import {PAGES_WITH_URLS} from '../constants/pages-with-urls.const';

export function createUrlForNewVersion(currentLang: string, currentWindow: Window): string {
  const firstChunksOfCompositePath = ['tx', 'profile', 'history', 'trade', 'ib-room', 'bonuses'];
  const secondChunksOfCompositePath = ['trade/platform'];

  const chunksOfPathname = currentWindow.location.pathname.split('/');

  let fullPath = currentWindow.location.origin;
  let firstRouteAfterLang = chunksOfPathname.at(2);

  const thirdPath = chunksOfPathname.at(3);
  if (firstChunksOfCompositePath.includes(firstRouteAfterLang)) {
    firstRouteAfterLang += '/' + thirdPath;
  }

  const fourthPath = chunksOfPathname.at(4);
  if (fourthPath && secondChunksOfCompositePath.includes(firstRouteAfterLang)) {
    firstRouteAfterLang += '/' + fourthPath;
  }

  if (firstRouteAfterLang) {
    const routeForOldVersion = PAGES_WITH_URLS.get(firstRouteAfterLang);

    if (routeForOldVersion) {
      fullPath += `/${currentLang}/${routeForOldVersion}`;
    }
  }

  if (chunksOfPathname.includes('ib-room')) {
    const partnerId = chunksOfPathname.at(
      chunksOfPathname.findIndex(chunk =>
        [
          'banners',
          'links',
          'acquisitions',
          'client-list',
          'accounts',
          'rewards',
          'transactions',
          'deposits',
          'withdrawals',
          'trades',
        ].some(item => item === chunk),
      ) + 1,
    );
    const clientId = chunksOfPathname.at(chunksOfPathname.indexOf('client-details') + 1);

    if (partnerId && clientId) {
      const routeForOldVersion = PAGES_WITH_URLS.get(
        chunksOfPathname.slice(2).join('/').replace(partnerId, ':partnerId').replace(clientId, ':clientId'),
      );

      if (routeForOldVersion) {
        fullPath += `/${currentLang}/${routeForOldVersion
          .replace(':partnerId', partnerId)
          .replace(':clientId', clientId)}`;
      }
    } else if (partnerId) {
      const routeForOldVersion = PAGES_WITH_URLS.get(
        chunksOfPathname.slice(2).join('/').replace(partnerId, ':partnerId'),
      );

      if (routeForOldVersion) {
        fullPath += `/${currentLang}/${routeForOldVersion.replace(':partnerId', partnerId)}`;
      }
    }
  }

  return fullPath;
}
